import React, {useEffect, useState} from 'react';
import aboutBannerDesktop from '../assets/images/about-banner.jpg';
import aboutBannerMobile from '../assets/images/about-banner-mobile.png';
import { Link } from 'react-router-dom';

export default function AboutBanner() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth <= 767); // Adjust width as needed for mobile
        };
        window.addEventListener('resize', handleResize);
        handleResize(); // Check initial size

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const aboutBanner = isMobile ? aboutBannerMobile : aboutBannerDesktop;
    return (
    <>
    <div className='about-banner position-relative'>
        <img src={aboutBanner} alt="banner" className='position-absolute'/>
        <div className='home-services-inner' data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
            <span className='font-11 text-uppercase text-white'>ABOUT IVEY</span>
            <h2 className='text-white font-thin'>Kirk Ivey has worked internationally, using his expertise in construction, interior design, and IT architecture to provide a comprehensive solution for clients.</h2>
            <Link to="/" className='view-all text-uppercase font-11 text-white'>contact us </Link>
            
        </div>
    </div>
    </>
   )
}
